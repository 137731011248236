
import {defineComponent} from "vue";
import axios from "axios";
import * as requestActions from "../store/requestActions";
import EuSignWrapper from "../services/eusign/EuSignWrapper";
import Popper from "vue3-popper";

export default defineComponent({
  name: "Signpage",
  props: ["roomId", "isAdmin"],
  components: {
    Popper,
  },
  data() {
    return {
      phoneNumber: '',
      loaded: false,
      activeTab: 'file',
      notFoundFilesToSign: false,
      alreadyLoaded: 0,
      selectedCA: null,
      password: '',
      fileListModalVisible: false,
      files: [], // Array to store the list of files from the API
      signingInProgress: false, // Flag to track if signing is in progress
      allFileSigned: false,
      error: false,
      allFilesCount: 0,
      signedAlreadyCounter: 0,
      allSignedCounter: 0,
      nonSignedFilesCount: 0,
      euSignWrapper: null,
      kmTypes: 0,
      kmDevices: null,
      kmPassword: "",
      caIndex: 0,
      reloginCounter: 0,

      scriptUrls: [
        '/worker.js',
        '/euscpt.js',
        '/euscpm.js',
        '/euscp.js',
      ],
      currentIndex: 0,

    };
  },
  watch: {
  },
  computed: {
    photo: function () {
      return this.files?.filter(i => i.type === 'photo')
    },
    video: function () {
      return this.files?.filter(i => i.type === 'video')
    },
  },
  methods: {
    switchSignMethod(tab) {
      this.activeTab = tab;
      if(this.activeTab === "file") {
        this.initialize();
      } else {
        this.initializeByToken();
      }
    },

    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("locale", locale);
        this.$router.go(0);
      }
    },

    playVideo(index) {
      const video = this.$refs[`videoRef-${index}`];
      video.play();
    },

    errorNotificationFunc(text) {
      requestActions.notify({
        type: "danger",
        text: text,
      });
      console.error(text);
    },
    successNotificationFunc(text) {
      requestActions.notify({
        type: "success",
        text: text,
      });
    },

    onSignKeySubmit() {
      // Handle form submit if needed
    },

    //=========File manipulation functions block=========//
    async arrayBuffToImg(type, url) {

      try {
        const inputLink = this.normalizeUrl(url);

        let response = await axios.get(process.env.VUE_APP_SERVER_LINK + inputLink, {
          responseType: 'arraybuffer',
          headers: !this.isAdmin ? {} : {
            "x-access-token": this.getToken()
          },
        });

        let buffer = response.data;

        response = null;

        const blob = new Blob([buffer], { type: type === 'photo' ? 'image/png' : 'video/webm' });

        buffer = null;

        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error converting arraybuffer to base64:', error);
        return null;
      }
    },

    getFileUrl() {
      if(this.isAdmin) {
        return process.env.VUE_APP_SERVER_LINK + `/room-file-signed/${this.roomId}`;
      } else {
        return process.env.VUE_APP_SERVER_LINK + `/room-file/${this.roomId}`;
      }
    },

    getToken() {
      return localStorage.getItem('token');
    },

    getHeaders() {
      if(this.isAdmin) {
        return {
          headers: {
            "x-access-token": this.getToken()
          }
        };
      } else {
        return { credentials: 'include', };
      }
    },

    async fetchFiles() {
      try {
        const response = await fetch(this.getFileUrl(), this.getHeaders());
        const data = await response.json();

        if(data?.files.length <= 0 ) {
          if(data?.returnToSign) {
            if(!this.isAdmin) {
              this.$emit('already-sign');
            }
          }
          this.$emit('hideSign');
          this.notFoundFilesToSign = true;
        }

        this.files = await Promise.all(data?.files?.map(async (file) => {
          return {
            name: file.url.split('/').pop(),
            url: this.isAdmin ? file.url : file.url.replace('/file', '/file-client'),
            type: file.type,
            extension: file.extension,
            signed: file.signed,
            inProgress: false,
            original: file.originalUrl ?? null,
            base64: !this.isAdmin ? await this.arrayBuffToImg(file.type, this.isAdmin ? file.url : file.url.replace('/file', '/file-client')) : null,
          };
        }));
        this.nonSignedFilesCount =  this.isAdmin ? this.files.length : this.files.filter((file) => !file.signed).length;
        if(data.files === undefined) {
          this.files = []
          return;
        }

        const allFilesCount = this.files.length;
        let signedAlreadyCounter = 0;
        for (const file of this.files) {
          if (file.signed && !this.isAdmin) {
            signedAlreadyCounter++;
          }
          if (signedAlreadyCounter === allFilesCount) {
            this.allFileSigned = true;
            if(!this.isAdmin) {
              this.$emit('already-sign');
            }
            this.$emit('hideSign');
            break;
          }
        }

        this.fileListModalVisible = true;
      } catch (error) {
        this.reloginCounter++;
        this.$emit('client-login', () => {
          if(this.reloginCounter <= 1) {
            this.fetchFiles();
          }
        })
        if(!this.isAdmin) {
          this.$emit('already-sign');
        }
        this.$emit('hideSign');
        this.notFoundFilesToSign = true;
        console.error('Error fetching files:', error);
      }
    },

    setFileInProgress(file) {
      file.inProgress = true;
      file.signed = false;
    },
    //=========File manipulation functions block=========//

    //=========Sign by token functions block=========//
    onErrorEuSignByToken(error) {
      // @ts-ignore
      __UI__.__LOADER_SCREEN__.hideLoader();
      let text = error;
      if(typeof text !== "string") {
        text = text.message;
      }
      switch (error.errorCode) {
        case 51:
          text += this.$t('files.error51');
          break;
        case 4097:
          text += this.$t('files.error4097');
          break;
      }
      console.log(error);
      this.errorNotificationFunc(text);
    },

    async initializeByToken() {
      this.euSignWrapper = new EuSignWrapper();
      this.euSignWrapper.errorNotifyFunc = (error) => {
        this.onErrorEuSignByToken(error)
      };
      this.euSignWrapper.roomId = this.roomId;
      this.euSignWrapper.saveUrl = process.env.VUE_APP_SERVER_LINK + "/sign-save";
      await this.euSignWrapper.loadCryptoLibrary();
      await this.euSignWrapper.initializeCryptoLibrary();
      await this.euSignWrapper.loadCAJson();
      await this.euSignWrapper.getKMTypes();
      await this.euSignWrapper.getKeyMediaDevices();

      const mediaDevices = [];

      this.euSignWrapper.kmTypes.forEach((kmType, index) => {
        const mediaDevice = {
          typeIndex: index,
          devices: [],
        }
        this.euSignWrapper.kmAllDevices[index].forEach((kmDevice, index) => {
          mediaDevice.devices.push({
            name: `${kmDevice}(${kmType})`,
            devIndex: index,
          })
        })
        mediaDevices.push(mediaDevice)
      })
      const kmDevicesSelect = document.getElementById('kmDevices');

      await this.euSignWrapper.setDevicesSelect(kmDevicesSelect, mediaDevices);
    },

    async signByToken(files) {
      if(this.kmDevices !== null) {
        const keyTypeAndDevice = this.kmDevices.split('|')
        const kmTypesSelect = keyTypeAndDevice[0];
        const kmDevicesSelect = keyTypeAndDevice[1];
        const kmPasswordInput = this.kmPassword;
        if(this.kmPassword !== "") {
          await this.euSignWrapper.setSettings(this.caIndex);
          const lastItem = files[files.length - 1];
          for (const file of files) {
            await this.processFile(file, kmTypesSelect, kmDevicesSelect, kmPasswordInput, lastItem);
          }
        } else {
          // @ts-ignore
          __UI__.__LOADER_SCREEN__.hideLoader();
        }
      } else {
        // @ts-ignore
        __UI__.__LOADER_SCREEN__.hideLoader();
      }
    },

    async processFile(file, kmTypesSelect, kmDevicesSelect, kmPasswordInput, lastItem) {
      const result = await this.prepareFileForSignByToken(file);

      return new Promise<void>((resolve) => {
        this.euSignWrapper.__fileToSignPrepare(result, (selectFile) => {
          let chooseTrueFile = false;
          let selectedFileName = selectFile;
          let delimiter = '\\';
          if (selectFile.includes(delimiter)) {
            selectedFileName = selectedFileName.split(delimiter);
          } else {
            delimiter = '/';
            selectedFileName = selectedFileName.split(delimiter);
          }
          chooseTrueFile = file.name === selectedFileName[selectedFileName.length -1];

          if(chooseTrueFile) {
            if (selectFile) {
              const signFile = selectFile + ".p7s";

              this.euSignWrapper.SignFile(
                  kmTypesSelect,
                  kmDevicesSelect,
                  kmPasswordInput,
                  selectFile,
                  signFile,
                  result,
                  true,
                  () => {
                    this.euSignWrapper.readFile(signFile, (fileUint8Arr) => {
                      this.euSignWrapper.deleteFile(selectFile.replace('.p7s', ''), () => {
                      });
                      this.euSignWrapper.deleteFile(signFile, () => {
                      });
                      this.euSignWrapper.deleteFile(selectFile, () => {
                      });
                      this.euSignWrapper.resetKey();
                      this.signedAlreadyCounter++;
                      this.files[file.idx].signed = true;
                      let isHash = false;
                      if (this.euSignWrapper.isFileLarge(result.blob)) {
                        isHash = true;
                      }
                      this.euSignWrapper.saveFile(file.name + ".p7s", fileUint8Arr, isHash, () => {
                        if (file.name === lastItem.name) {
                          this.endSign();
                        }
                        resolve(); // Разрешаем промис после завершения текущей итерации
                      });
                    });
                    this.successNotificationFunc(this.$t('files.signed'));
                  },
                  (error) => {
                    this.euSignWrapper.deleteFile(selectFile.replace('.p7s', ''), () => {
                    });
                    this.euSignWrapper.deleteFile(signFile, () => {
                    });
                    this.euSignWrapper.deleteFile(selectFile, () => {
                    });
                    this.errorNotificationFunc(error);
                    resolve(); // Разрешаем промис после завершения текущей итерации
                  }
              );
            }
          } else {
            this.errorNotificationFunc(this.$t("files.notValidChooseFile").replace("%s", `${this.euSignWrapper.installedPath}${delimiter}${file.name}`));
            // @ts-ignore
            __UI__.__LOADER_SCREEN__.hideLoader();
          }
        })
      });
    },

    async prepareFileForSignByToken(file) {
      try {
        this.setFileInProgress(file);
        const inputLink = this.normalizeUrl(file.url);
        let originalFileLink = null;
        if(file.original) {
          originalFileLink = this.normalizeUrl(file.original);
        }

        const response = await axios.get(process.env.VUE_APP_SERVER_LINK + inputLink, {
          responseType: 'arraybuffer',
          headers: !this.isAdmin ? {} : {
            "x-access-token": this.getToken()
          },
        });
        let arrayBuffer = response.data;
        let uint8Array = new Uint8Array(arrayBuffer);

        // @ts-ignore
        arrayBuffer = null;

        let uint8ArrayOriginal = null;

        if(file.original) {
          originalFileLink = this.normalizeUrl(file.original);
          const responseOriginal = await axios.get(process.env.VUE_APP_SERVER_LINK + originalFileLink, {
            responseType: 'arraybuffer',
            headers: !this.isAdmin ? {} : {
              "x-access-token": this.getToken()
            },
          });
          let arrayBuffer = responseOriginal.data;
          uint8ArrayOriginal = new Uint8Array(arrayBuffer);
          arrayBuffer = null;
        }

        return {
          filename: file.name,
          blob: uint8Array,
          originalBlob: uint8ArrayOriginal
        }
      } catch (error) {
        this.onErrorEuSignByToken(error);
      } finally {
        this.files[file.idx].inProgress = false;
      }
    },
    //=========Sign by token functions block=========//

    //=========Sign functions block=========//
    async signAllFiles() {
      this.signingInProgress = true;
      this.allFilesCount = this.files.length;
      this.signedAlreadyCounter = 0;
      this.files.forEach((file, idx) => {
        this.files[idx].idx = idx;
      })
      let files = JSON.parse(JSON.stringify(this.files));
      if(!this.isAdmin) {
        files = this.files.filter((file) => !file.signed)
      }
      if(this.activeTab === 'file') {
        for (const file of files) {
          await this.signFile(file)
          this.signedAlreadyCounter++;
        }
      } else {
        this.signByToken(files);
      }


      this.signingInProgress = false;
    },

    endSign() {
      this.allFileSigned = true;
      if(!this.isAdmin) {
        this.$emit('already-sign');
      }
      this.$emit('hideSign');
      if(this.isAdmin) {
        this.$emit('signedBy', 'admin');
        this.$emit('setFullSigned');
      }
      // @ts-ignore
      __UI__.__LOADER_SCREEN__.hideLoader();
    },

    setCaIndex(idx) {
      this.caIndex = idx;
    },

    async onTokenReadClick() {
      if (this.kmDevices !== null) {
        const keyTypeAndDevice = this.kmDevices.split('|')
        const kmTypesSelect = keyTypeAndDevice[0];
        const kmDevicesSelect = keyTypeAndDevice[1];
        const kmPasswordInput = this.kmPassword;
        if (this.kmPassword !== "") {
          this.euSignWrapper.readKey(kmTypesSelect, kmDevicesSelect, kmPasswordInput, this.setCaIndex);
        }
      }
    },

    onFileSignClick() {
      // @ts-ignore
      __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.signFile"), '255,255,255,1', '0,0,0,1');
      this.signAllFiles();
    },
    //=========Sign functions block=========//

    //=========Sign by file functions block=========//
    initialize() {
      // @ts-ignore
      setURL_XML_HTTP_PROXY_SERVICE(process.env.VUE_APP_SERVER_LINK + "/sign-proxy");
      // @ts-ignore
      setRoomId(this.roomId);
      // @ts-ignore
      setSaveUrl(process.env.VUE_APP_SERVER_LINK + "/sign-save");
      // @ts-ignore
      resetSignedSavedFilesCounter();
      //@ts-ignore
      setNotificationFunc(this.errorNotificationFunc);
      //@ts-ignore
      EUSignCPModuleInitializing();
    },

    onSignReadClick() {
      //@ts-ignore
      __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.readKey"), '255,255,255,1', '0,0,0,1');
      const password = this.$refs.password;
      //@ts-ignore
      const key = document.querySelector('#sign-file').files;
      //@ts-ignore
      __PRVT_KEY__.readKey(key, password);
    },

    normalizeUrl(url) {
      const domainIndex = url.indexOf("://");
      const pathStart = domainIndex !== -1 ? url.indexOf("/", domainIndex + 3) : url.indexOf("/");

      if (pathStart !== -1) {
        return url.substring(pathStart);
      }

      return "/";
    },

    async signFile(file) {
      try {
        this.setFileInProgress(file);
        const inputLink = this.normalizeUrl(file.url);
        let originalFileLink = null;
        if(file.original) {
          originalFileLink = this.normalizeUrl(file.original);
        }

        const response = await axios.get(process.env.VUE_APP_SERVER_LINK + inputLink, {
          responseType: 'arraybuffer',
          headers: !this.isAdmin ? {} : {
            "x-access-token": this.getToken()
          },
        });
        let arrayBuffer = response.data;
        let uint8Array = new Uint8Array(arrayBuffer);

        // @ts-ignore
        const fileObj = new File([arrayBuffer], file.name);
        arrayBuffer = null;

        let uint8ArrayOriginal = null;

        if(file.original) {
          originalFileLink = this.normalizeUrl(file.original);
          const responseOriginal = await axios.get(process.env.VUE_APP_SERVER_LINK + originalFileLink, {
            responseType: 'arraybuffer',
            headers: !this.isAdmin ? {} : {
              "x-access-token": this.getToken()
            },
          });
          let arrayBuffer = responseOriginal.data;
          uint8ArrayOriginal = new Uint8Array(arrayBuffer);
          arrayBuffer = null;
        }

        // @ts-ignore
        await __SIGN__.signFileRaw(file.name, uint8Array, fileObj, uint8ArrayOriginal, this.endSign, this.nonSignedFilesCount)

        uint8Array.fill(0);

        this.files[file.idx].signed = true;

      } catch (error) {
        console.error('Error signing file:', error);
      } finally {
        this.files[file.idx].inProgress = false;
      }
    },

    loadFiles() {
      //@ts-ignore
      __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.init"), '255,255,255,1', '0,0,0,1', 'width: auto;');
      this.fetchFiles().then(() => {
        //@ts-ignore
        __UI__.__LOADER_SCREEN__.hideLoader();
      });
    },

    loadFilesAndInitializeLib() {
      //@ts-ignore
      if(!__UI__.__LOADER_SCREEN__.initialize) {
        //@ts-ignore
        __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.init"), '255,255,255,1', '0,0,0,1', 'width: auto;');
      }
      this.fetchFiles()
          .then(() => {
            if(!this.loaded && !this.allFileSigned && !this.notFoundFilesToSign) {
              this.initialize()
            }
            this.loaded = true;
          })
          .catch((error) => {
            console.log(error, 'ERROR fetchFiles')
          });
    },

    isScriptAdded(src) {
      return Boolean(document.querySelector('script[src="' + src + '"]'));
    },

    scriptsAlreadyLoaded() {
      const mustBeLoaded = this.scriptUrls.length;

      for(let script of this.scriptUrls) {
        const isScriptAdded = this.isScriptAdded(script);
        this.alreadyLoaded = isScriptAdded ? this.alreadyLoaded + 1 : this.alreadyLoaded;
      }

      const fullyLoad = this.alreadyLoaded === mustBeLoaded;

      if(fullyLoad) {
        this.loadFilesAndInitializeLib()
      }

      return fullyLoad;
    },

    loadScriptsOnSignRoute() {

      if(this.scriptsAlreadyLoaded()) {
        this.currentIndex = this.scriptUrls.length + 1;
      }

      if (this.currentIndex < this.scriptUrls.length) {
        const script = document.createElement("script");
        script.src = this.scriptUrls[this.currentIndex];
        script.onload = () => {
          if (this.currentIndex === 0) {
            //@ts-ignore
            __UI__.__LOADER_SCREEN__.showLoader(this.$t("files.init"), '255,255,255,1', '0,0,0,0.7', 'width: auto;');
          }
          this.currentIndex++;

          if (this.currentIndex === 4) {
            this.loadFilesAndInitializeLib()
            if(this.isAdmin) {
              this.$emit('signedBy', 'client');
            }
          }

          return this.loadScriptsOnSignRoute();
        };

        if(!this.isScriptAdded(script)) {
          document.head.appendChild(script);
        }
      }
    },
    //=========Sign by file functions block=========//
  },
  mounted() {
    if (this.$route.query.locale) {
      this.switchLocale(this.$route.query.locale);
    }
    this.loadScriptsOnSignRoute()
  },
});
